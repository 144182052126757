import React, { useLayoutEffect, useContext } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { LangContext } from "../context/LangContext"

import { Container, Section } from "../components/anti/grid/grid"

import { AcfColHeadline } from "../components/gutenberg/acf-col-headline"
import { AcfCoverMain } from "../components/gutenberg/acf-cover-main"
import { isConstructorDeclaration } from "typescript"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const AccessDetailsPage = ({ pageContext, location }) => {
  const { lang, setLang } = useContext(LangContext)
  const wp = pageContext.data.blocks
  const wpID = pageContext.data.translation && pageContext.data.translation.blocks
  const { seo } = pageContext.langKey === "en"
    ? pageContext.data
    : pageContext.data.translation
    || pageContext.data

  // Modify navbar style
  useLayoutEffect(() => {
    const navbar = document.querySelector(".navbar")
    navbar.classList.remove("transparent")
    navbar.classList.replace("dark", "light")
  }, [])

  const SEODefaultImg = lang === "EN"
    ? pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl
    : pageContext?.data?.translation?.blocks[0]?.coverMain?.img?.mediaItemUrl
    || pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl

  return (
    <Layout>
      <SEO
        title={seo.title}
        desc={seo.metaDesc}
        fbImg={seo.opengraphImage?.sourceUrl || SEODefaultImg}
        fbTitle={seo.opengraphTitle}
        fbDesc={seo.opengraphDescription}
        url={location.href}
        twitterTitle={seo.twitterTitle}
        twitterDesc={seo.twitterDescription}
        twitterImg={seo.twitterImage?.sourceUrl || SEODefaultImg}
      />
      {lang == "ID" && wpID &&
        wpID.map((block, i) => {
          switch (block.name) {
            // case "core/cover":
            //   return (
            //     <Cover
            //       variant="basic"
            //       theme="dark"
            //       breadcrumb={<Breadcrumb />}
            //       img={block.attributes.url}
            //       imgHeight="h-300px h-md-400px"
            //       imgOverlay="40"
            //       title={pageContext.data.title}
            //       key={i}
            //     />
            //   )

            case "acf/cover-main":
              return (
                <AcfCoverMain
                  data={block}
                  key={`${block.name}${i}`}
                  breadcrumb={location.pathname}
                />
              )

            case "acf/col-headline":
              return <AcfColHeadline data={block} />

            case "core/image":
              return (
                <Section className="p-0">
                  <Container>
                    <div>
                      <img
                        src={block.attributes.url}
                        className={`img-fluid w-100`}
                        alt="Image"
                      />
                    </div>
                  </Container>
                </Section>
              )
            case "acf/iconlist":
              return (
                <Section className={`${block.acfBlockIconList.bgColor || ""} `}>
                  <Container>
                    <h2 className="h2 mb-4">{block.acfBlockIconList.title}</h2>
                    <div className="access-icon-list bus">
                      <div className="row">
                        {block.acfBlockIconList.list.map((item, i) => {
                          return (
                            <div className="col-md-6" key={i}>
                              <div className="icon-list-item">
                                <img src={item.icon.mediaItemUrl} alt="Icon" />
                                <p>{item.title}</p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </Container>
                </Section>
              )
            case "acf/text-w-list":
              return (
                <Section
                  className={`${block.textWList.bgColor || ""} ${block.attributes
                    .className || ""}`}
                >
                  <Container>
                    <h2 className="h2">{block.textWList.title}</h2>
                    <div className="row">
                      <div className="col-md-6">
                        {block.textWList.list.map((item, i) => {
                          return <p key={i}>{item.text}</p>
                        })}
                      </div>
                    </div>
                  </Container>
                </Section>
              )
          }
        })}

      {lang == "ID" && wpID == null &&
        wp.map((block, i) => {
          switch (block.name) {
            // case "core/cover":
            //   return (
            //     <Cover
            //       variant="basic"
            //       theme="dark"
            //       breadcrumb={<Breadcrumb />}
            //       img={block.attributes.url}
            //       imgHeight="h-300px h-md-400px"
            //       imgOverlay="40"
            //       title={pageContext.data.title}
            //       key={i}
            //     />
            //   )

            case "acf/cover-main":
              return (
                <AcfCoverMain
                  data={block}
                  key={`${block.name}${i}`}
                  breadcrumb={location.pathname}
                />
              )

            case "acf/col-headline":
              return <AcfColHeadline data={block} />

            case "core/image":
              return (
                <Section className="p-0">
                  <Container>
                    <div>
                      <img
                        src={block.attributes.url}
                        className={`img-fluid w-100`}
                        alt="Image"
                      />
                    </div>
                  </Container>
                </Section>
              )
            case "acf/iconlist":
              return (
                <Section className={`${block.acfBlockIconList.bgColor || ""} `}>
                  <Container>
                    <h2 className="h2 mb-4">{block.acfBlockIconList.title}</h2>
                    <div className="access-icon-list bus">
                      <div className="row">
                        {block.acfBlockIconList.list.map((item, i) => {
                          return (
                            <div className="col-md-6" key={i}>
                              <div className="icon-list-item">
                                <img src={item.icon.mediaItemUrl} alt="Icon" />
                                <p>{item.title}</p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </Container>
                </Section>
              )
            case "acf/text-w-list":
              return (
                <Section
                  className={`${block.textWList.bgColor || ""} ${block.attributes
                    .className || ""}`}
                >
                  <Container>
                    <h2 className="h2">{block.textWList.title}</h2>
                    <div className="row">
                      <div className="col-md-6">
                        {block.textWList.list.map((item, i) => {
                          return <p key={i}>{item.text}</p>
                        })}
                      </div>
                    </div>
                  </Container>
                </Section>
              )
          }
        })}

      {lang == "EN" && wp && wp.map((block, i) => {
        switch (block.name) {
          // case "core/cover":
          //   return (
          //     <Cover
          //       variant="basic"
          //       theme="dark"
          //       breadcrumb={<Breadcrumb />}
          //       img={block.attributes.url}
          //       imgHeight="h-300px h-md-400px"
          //       imgOverlay="40"
          //       title={pageContext.data.title}
          //       key={i}
          //     />
          //   )

          case "acf/cover-main":
            return (
              <AcfCoverMain
                data={block}
                key={`${block.name}${i}`}
                breadcrumb={location.pathname}
              />
            )

          case "acf/col-headline":
            return <AcfColHeadline data={block} />

          case "core/image":
            return (
              <Section className="p-0">
                <Container>
                  <div>
                    <img
                      src={block.attributes.url}
                      className={`img-fluid w-100`}
                      alt="Image"
                    />
                  </div>
                </Container>
              </Section>
            )
          case "acf/iconlist":
            return (
              <Section className={`${block.acfBlockIconList.bgColor || ""} `}>
                <Container>
                  <h2 className="h2 mb-4">{block.acfBlockIconList.title}</h2>
                  <div className="access-icon-list bus">
                    <div className="row">
                      {block.acfBlockIconList.list.map((item, i) => {
                        return (
                          <div className="col-md-6" key={i}>
                            <div className="icon-list-item">
                              <img src={item.icon.mediaItemUrl} alt="Icon" />
                              <p>{item.title}</p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </Container>
              </Section>
            )
          case "acf/text-w-list":
            return (
              <Section
                className={`${block.textWList.bgColor || ""} ${block.attributes
                  .className || ""}`}
              >
                <Container>
                  <h2 className="h2">{block.textWList.title}</h2>
                  <div className="row">
                    <div className="col-md-6">
                      {block.textWList.list.map((item, i) => {
                        return <p key={i}>{item.text}</p>
                      })}
                    </div>
                  </div>
                </Container>
              </Section>
            )
        }
      })}
    </Layout>
  )
}

export default AccessDetailsPage
